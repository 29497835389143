import React, { useEffect, useState } from "react"
import * as styles from './style.module.scss';
import InfoIcon from '../../../images/Icons/info.svg';

import { validate } from "../../../services/validation";

const Checkbox = (props) => {

    const [checked, setChecked] = useState(false);
    const [isInit, setIsInit] = useState(true);

    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);

    useEffect(() => {

        if (props.error === true && !hasError) {
            setHasError(true);
        } else if (props.error === false && hasError) {
            setHasError(false);
        }

    }, [props.error])

    let rules = {};

    if (props.required) {
        rules.isTrue = true;
    }

    useEffect(() => {

        if(isInit){
            setIsInit(false);
            return false;
        }

        if (rules.length < 1) return;

        let valid = validate(checked, rules);

        if (valid) {
            setHasSuccess(true);
            setHasError(false);
        } else {
            setHasError(true);
            setHasSuccess(false);
        }


    }, [checked])

    return (
        <div
            className={`${styles.checkboxContainer} ${checked ? styles.checked : null} ${hasError ? styles.error : null} ${hasSuccess ? styles.success : null}`} style={props.style}
        >
            <label>
                <div className={styles.checkbox}></div>
                <div className={`${styles.label}`}>
                    {props.label}
                    {
                        props.description !== undefined && props.description !== '' &&
                        <div className={styles.infoContainer}>
                            <InfoIcon />
                            <div className={styles.textContainer}>{props.description}</div>
                        </div>
                    }
                </div>
                <input
                    {...props}
                    className={styles.realCheckbox}
                    type={"checkbox"}
                    style={{}}
                    onChange={(e) => { setChecked(prev => (!prev)); props.onChange(e) }}
                />
            </label>

        </div>
    )
}

export default Checkbox;
