import axios from "axios";

const isLoggedIn = async () => {

    let userToken = localStorage.getItem("token");
    if (userToken == null) userToken = sessionStorage.getItem("token");

    console.log("userToken", userToken)

    if (userToken !== null) {

        return true;

    } else {
        return false;
    }

}

export default isLoggedIn;

export const checkLoggedIn = () => {
    let userToken = localStorage.getItem("token");
    if (userToken == null) userToken = sessionStorage.getItem("token");

    if (userToken !== null) {

        return true;

    } else {
        return false;
    }

}


export const getUserData = () => {

    let userData = localStorage.getItem("userdata");
    if (userData == null) userData = sessionStorage.getItem("userdata");

    userData = JSON.parse(userData);
    console.log("userData", userData);
    return userData;

}

export const setUserData = (data) => {

    let userData = localStorage.getItem("userdata");
    if (userData == null) {
        sessionStorage.setItem("userdata", JSON.stringify(data))
    } else {
        localStorage.setItem("userdata", JSON.stringify(data))
    }

    return true;

}

export const getUserToken = () => {
    let token = localStorage.getItem("token");
    if (token == null) token = sessionStorage.getItem("token");

    return token;
}