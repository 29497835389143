import { Link, navigate, withPrefix } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import isLoggedIn, { getUserToken } from "../../../services/auth"
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import Layout from "../../../components/layout"
import Button from "../../../components/elements/Button"
import TextInput from "../../../components/elements/Inputs/TextInput";
import Checkbox from "../../../components/elements/Inputs/Checkbox";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep } from "../../../store/actions";


/*

http://localhost:8000/kasse/waiting/2341631/lukas.greul%40me.com?payment_intent=pi_3JURKfGJ1lhugf3L0Pc6UNEK&payment_intent_client_secret=pi_3JURKfGJ1lhugf3L0Pc6UNEK_secret_TaGVnKgl5MLaTgefdIjJSpJNF&redirect_status=succeeded

*/
const Waiting = (props) => {

    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);

    

    const getPaypalStatus = (token) => {

        const isBrowser = typeof window !== "undefined";
        let userToken = null;

        if (isBrowser) {
            userToken = localStorage.getItem('user');
        }

        let data = JSON.stringify({
            "paypal_token": token
        });

        var config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/shop/order/paypal/status',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                if (response.data.status === 'COMPLETED') {
                    navigate(withPrefix(`/kasse/success/${props.orderId}/${props.email}`));
                } else if (response.data.status === 'APPROVED') {

                    setTimeout(() => getPaypalStatus(token), 3000);

                } else {
                    navigate(withPrefix('/kasse/error'));
                }

            })
            .catch(function (error) {
                console.log(error);
            });



    }

    useEffect(() => {

        if (props.email == undefined || props.orderId == undefined) {
            return false;
        }

        let searchParams = new URLSearchParams(window.location.search)

        // CHECK IS STRIPE
        if (searchParams.get('redirect_status') !== null) {
            let status = searchParams.get('redirect_status');
            if (status === 'succeeded') {
                navigate(withPrefix(`/kasse/success/${props.orderId}/${props.email}`));
            } else if (status === 'failed') {
                navigate(withPrefix('/kasse/error'));
            }
        } else if (searchParams.get('token') !== null) { // CHECK IS PAYPAL
            let paypalToken = searchParams.get('token');

            getPaypalStatus(paypalToken)

        }




    }, [])


    const dispatch = useDispatch();
    dispatch(changeCheckoutStep({ currentStep: 'payment' }));

    return (
        <Layout path={props.path}>
            <div className="container" style={{ textAlign: 'center', marginTop: 100, marginBottom: 100, minHeight: 'calc(100vh - 600px)' }}>
                <div className="col12">
                    <div>
                        <LoadingSpinner active={true} type="container" />
                        <h4>Wir bearbeiten Ihre Bestellung</h4>
                        <strong>Das kann einige Sekunden dauern.</strong>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Waiting;
